
let PiecesObj = {
    "king_white" : {
        "color" : "true",
        "x" : '1',
        "y" : '5',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/42/Chess_klt45.svg", 
        "alive" : 1,
        "hasMoved" : false
    },
    "queen_white" : {
        "color" : "true",
        "x" : '1',
        "y" : '4',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/1/15/Chess_qlt45.svg", 
        "alive" : 1,
    },
    "bishop1_white" : {
        "color" : "true",
        "x" : '1',
        "y" : '3',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/b/b1/Chess_blt45.svg", 
        "alive" : 1,
    },
    "bishop2_white" : {
        "color" : "true",
        "x" : '1',
        "y" : '6',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/b/b1/Chess_blt45.svg", 
        "alive" : 1,
    },
    "rook1_white" : {
        "color" : "true",
        "x" : '1',
        "y" : '1',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/7/72/Chess_rlt45.svg", 
        "alive" : 1,
        "hasMoved" : false
    },
    "rook2_white" : {
        "color" : "true",
        "x" : '1',
        "y" : '8',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/7/72/Chess_rlt45.svg", 
        "alive" : 1,
        "hasMoved" : false
    },
    "knight1_white" : {
        "color" : "true",
        "x" : '1',
        "y" : '2',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/7/70/Chess_nlt45.svg", 
        "alive" : 1,
    },
    "knight2_white" : {
        "color" : "true",
        "x" : '1',
        "y" : '7',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/7/70/Chess_nlt45.svg", 
        "alive" : 1,
    },
    "pawn1_white" : {
        "color" : "true",
        "x" : '2',
        "y" : '1',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg", 
        "alive" : 1,
    },
    "pawn2_white" : {
        "color" : "true",
        "x" : '2',
        "y" : '2',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg", 
        "alive" : 1,
    },
    "pawn3_white" : {
        "color" : "true",
        "x" : '2',
        "y" : '3',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg", 
        "alive" : 1,
    },
    "pawn4_white" : {
        "color" : "true",
        "x" : '2',
        "y" : '4',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg", 
        "alive" : 1,
    },
    "pawn5_white" : {
        "color" : "true",
        "x" : '2',
        "y" : '5',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg", 
        "alive" : 1,
    },
    "pawn6_white" : {
        "color" : "true",
        "x" : '2',
        "y" : '6',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg", 
        "alive" : 1,
    },
    "pawn7_white" : {
        "color" : "true",
        "x" : '2',
        "y" : '7',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg", 
        "alive" : 1,
    },
    "pawn8_white" : {
        "color" : "true",
        "x" : '2',
        "y" : '8',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg", 
        "alive" : 1,
    },

    "king_black" : {
        "color" : "false",
        "x" : '8',
        "y" : '5',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/f/f0/Chess_kdt45.svg", 
        "alive" : 1,
        "hasMoved" : false
    },
    "queen_black" : {
        "color" : "false",
        "x" : '8',
        "y" : '4',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/4/47/Chess_qdt45.svg", 
        "alive" : 1,
    },
    "bishop1_black" : {
        "color" : "false",
        "x" : '8',
        "y" : '3',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/9/98/Chess_bdt45.svg", 
        "alive" : 1,
    },
    "bishop2_black" : {
        "color" : "false",
        "x" : '8',
        "y" : '6',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/9/98/Chess_bdt45.svg", 
        "alive" : 1,
    },
    "rook1_black" : {
        "color" : "false",
        "x" : '8',
        "y" : '1',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/f/ff/Chess_rdt45.svg", 
        "alive" : 1,
        "hasMoved" : false
    },
    "rook2_black" : {
        "color" : "false",
        "x" : '8',
        "y" : '8',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/f/ff/Chess_rdt45.svg", 
        "alive" : 1,
        "hasMoved" : false
    },
    "knight1_black" : {
        "color" : "false",
        "x" : '8',
        "y" : '2',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/e/ef/Chess_ndt45.svg", 
        "alive" : 1,
    },
    "knight2_black" : {
        "color" : "false",
        "x" : '8',
        "y" : '7',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/e/ef/Chess_ndt45.svg", 
        "alive" : 1,
    },
    "pawn1_black" : {
        "color" : "false",
        "x" : '7',
        "y" : '1',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg", 
        "alive" : 1,
    },
    "pawn2_black" : {
        "color" : "false",
        "x" : '7',
        "y" : '2',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg", 
        "alive" : 1,
    },
    "pawn3_black" : {
        "color" : "false",
        "x" : '7',
        "y" : '3',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg", 
        "alive" : 1,
    },
    "pawn4_black" : {
        "color" : "false",
        "x" : '7',
        "y" : '4',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg", 
        "alive" : 1,
    },
    "pawn5_black" : {
        "color" : "false",
        "x" : '7',
        "y" : '5',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg", 
        "alive" : 1,
    },
    "pawn6_black" : {
        "color" : "false",
        "x" : '7',
        "y" : '6',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg", 
        "alive" : 1,
    },
    "pawn7_black" : {
        "color" : "false",
        "x" : '7',
        "y" : '7',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg", 
        "alive" : 1,
    },
    "pawn8_black" : {
        "color" : "false",
        "x" : '7',
        "y" : '8',
        "img" : "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg", 
        "alive" : 1,
    },
    
}

export default PiecesObj